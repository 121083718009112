import { FC, CSSProperties } from "react";
import {
  StyleSheet,
  css,
  CSSProperties as CSSAphrodite,
  StyleDeclaration,
} from "aphrodite";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import Text from "@ui/text";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import useHover from "@hooks/useHover";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";

type Props = {
  checked: boolean;
  onChange?: (value: boolean, evt: React.MouseEvent<HTMLElement>) => void;
  label?: React.ReactNode;
  disabled?: boolean;
  inline?: boolean;
  style?: CSSProperties;
  styleDeclaration?: StyleDeclaration;
  checkboxIcon?: React.ReactNode;
};

const Checkbox: FC<Props> = ({
  checked,
  onChange,
  label,
  disabled,
  inline = false,
  style,
  styleDeclaration,
  checkboxIcon,
}) => {
  const [isHovered, hoverProps] = useHover();
  const showCheckbox = isHovered || !checkboxIcon || checked;
  return (
    <div
      onClick={(e) => {
        if (!disabled) {
          onChange?.(!checked, e);
        }
      }}
      className={css(
        styles.container,
        inline ? styles.inline : styles.block,
        styleDeclaration,
      )}
      style={style}
      {...hoverProps}
    >
      <input
        className={css(styles.input)}
        type="checkbox"
        checked={checked}
        readOnly
        disabled={disabled}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <AutoLayout
          height={14}
          width={14}
          style={{
            position: "relative",
          }}
        >
          <AutoLayout
            alignmentVertical={"center"}
            alignmentHorizontal={"center"}
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
            }}
          >
            {showCheckbox ? (
              <div
                style={{ cursor: disabled ? "not-allowed" : "pointer" }}
                className={css(
                  disabled &&
                    (checked
                      ? styles.checkedDisabled
                      : styles.uncheckedDisabled),
                  !disabled && (checked ? styles.checked : styles.unchecked),
                )}
              >
                {checked && (
                  <DeprecatedIcon
                    type="checkmark"
                    containerSize={20}
                    color={deprecatedTones.white}
                  />
                )}
              </div>
            ) : (
              checkboxIcon
            )}
          </AutoLayout>
        </AutoLayout>
        {!!label &&
          (typeof label === "string" ? (
            <Text type="P2" styleDeclaration={styles.label} ellipsis={true}>
              {label}
            </Text>
          ) : (
            label
          ))}
      </div>
    </div>
  );
};

const checkboxBase: CSSAphrodite = {
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  width: 14,
  height: 14,
  borderRadius: 4,
  transform: "translate(0, 1px)",
  outline: "none",
};
const styles = StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    margin: 0,
    cursor: "pointer",
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 6,
  },
  unchecked: {
    border: `1px solid ${deprecatedTones.gray5}`,
    backgroundColor: deprecatedTones.white,
    flexShrink: 0,
    ...checkboxBase,
  },
  checked: {
    border: `1px solid ${deprecatedTones.blue9}`,
    backgroundColor: deprecatedTones.blue9,
    ...checkboxBase,
  },
  checkedDisabled: {
    backgroundColor: deprecatedTones.blue5,
    border: `1px solid ${deprecatedTones.blue5}`,
    ...checkboxBase,
  },
  uncheckedDisabled: {
    border: `1px solid ${deprecatedTones.gray5}`,
    backgroundColor: deprecatedTones.gray5Alpha,
    ...checkboxBase,
  },
  input: {
    display: "none",
  },
  label: {
    position: "relative",
    top: 1,
    marginLeft: 8,
  },
  inline: {
    display: "inline-block",
  },
  block: {
    display: "block",
  },
});

export default Checkbox;
