import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Button from "@src/deprecatedDesignSystem/components/Button";
import MultiSelectBanner, {
  MultiSelectAction,
} from "@src/deprecatedDesignSystem/components/MultiSelectBanner";
import { TableContentRow } from "@src/deprecatedDesignSystem/components/table/TableContentRow";
import { TableHeaderRow } from "@src/deprecatedDesignSystem/components/table/TableHeaderRow";
import TableSkeletonState from "@src/deprecatedDesignSystem/components/table/TableSkeletonState";
import Text from "@ui/text";
import TextField from "@src/deprecatedDesignSystem/components/TextField";
import { useScreenDimensions } from "@hooks/useScreenDimensions";
import { CsvCell, downloadCSV } from "@utils/csv";
import Fuse from "fuse.js";
import { orderBy } from "lodash";
import React, {
  CSSProperties,
  FC,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import { ViewportList } from "react-viewport-list";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { Route } from "nextjs-routes";
import { StyleSheet } from "aphrodite";

export type RowHeight =
  | number
  | { wrap: true; minHeight: number; maxHeight: number };
export interface TableProps<T, K extends string> {
  title?: string | React.ReactNode;
  allItems: T[];
  allColumns: K[];
  itemId: (item: T) => string | number;
  visibleColumns?: () => K[];
  hiddenColumns?: () => K[];
  columnTitles?: Record<K, string>;
  renderFns: RenderFns<T, K>;
  headerCellStyles?: HeaderCellStyles<K>;
  responsiveness?: ResponsivenessConfigs<K>;
  sortingFns?: SortingFns<T, K>;
  multiselect?: MultiSelectConfig<T>;
  filterItems?: (allItems: T[]) => T[];
  defaultSort?: SortConfig<K>;
  rowStyles?: CSSProperties;
  rowHeight?: RowHeight;
  onRowClick?: (item: T) => void;
  rowClickLink?: (item: T) => Route | undefined | null;
  disableRowClickOnColumns?: Set<K>;
  overflowMenu?: (item: T) => React.ReactNode;
  fuseSearchConfig?: FuseSearchConfig<T>;
  rightAction?: React.ReactNode;
  loading?: boolean;
  headerStickyPositioning?: number;
  noDataEmptyState?: React.ReactNode;
  exportConfig?: ExportConfig<T, K>;
  showCount?: boolean;
  disabledSorts?: K[];
  emptyStatePaddingTop?: number;
  activeColumn?: K;
  onColumnHeaderClick?: (column: K) => void;
  headerHeight?: number;
  dataTestId?: string;
}

export default function Table<T, K extends string>({
  title,
  allItems,
  allColumns,
  itemId,
  visibleColumns,
  hiddenColumns,
  columnTitles,
  renderFns,
  responsiveness,
  sortingFns,
  filterItems,
  defaultSort,
  multiselect,
  rowStyles,
  rowHeight = 42,
  onRowClick,
  rowClickLink,
  overflowMenu,
  fuseSearchConfig,
  rightAction,
  loading,
  headerStickyPositioning,
  disableRowClickOnColumns,
  noDataEmptyState,
  exportConfig,
  showCount = true,
  disabledSorts,
  emptyStatePaddingTop = 80,
  activeColumn,
  headerCellStyles,
  onColumnHeaderClick,
  headerHeight,
  dataTestId,
}: TableProps<T, K>): JSX.Element {
  const [selectedSort, setSelectedSort] = useState<SortConfig<K> | undefined>(
    defaultSort,
  );
  const [searchValue, setSearchValue] = useQueryParam(
    "q",
    withDefault(StringParam, ""),
  );
  let items = allItems;
  items = useMemo(() => {
    if (!sortingFns || !selectedSort) {
      return items;
    }
    const sortFn = sortingFns[selectedSort.column];
    if (!sortFn) {
      return items;
    }
    let sortedItems = orderBy(items, sortFn);
    const sortValuesToPutOnBottom: Set<SortFnReturnValue> = new Set([
      undefined,
      null,
      "",
    ]);
    const bottomItems = sortedItems.filter((x) =>
      sortValuesToPutOnBottom.has(sortFn(x)),
    );
    let topItems = sortedItems.filter(
      (x) => !sortValuesToPutOnBottom.has(sortFn(x)),
    );
    if (selectedSort.descending) {
      topItems = topItems.reverse();
    }
    sortedItems = [...topItems, ...bottomItems];
    return sortedItems;
  }, [items, sortingFns, selectedSort]);
  items = useMemo(() => {
    if (filterItems) {
      return filterItems(items);
    }
    return items;
  }, [filterItems, items]);
  items = useMemo(() => {
    if (searchValue && fuseSearchConfig) {
      return new Fuse(items, fuseSearchConfig)
        .search(searchValue)
        .map((m) => m.item);
    } else {
      return items;
    }
  }, [fuseSearchConfig, items, searchValue]);
  const [lastSelectedItemId, setLastSelectedItemId] = useState<KeyType>();
  const [allFilteredItemsSelected, setAllFilteredItemsSelected] =
    useState(false);
  const [selectedItemIds, setSelectedItemIds] = useState<Set<KeyType>>(
    new Set(),
  );
  useEffect(() => {
    setSelectedItemIds((prevSelectedItemIds) => {
      const validIds = new Set(allItems.map((item) => itemId(item)));
      return new Set([...prevSelectedItemIds].filter((id) => validIds.has(id)));
    });
  }, [allItems, itemId]);
  const toggleItemSelected = useCallback(
    (item: T, holdingShift: boolean) => {
      setLastSelectedItemId(itemId(item));
      setSelectedItemIds((oldState) => {
        const newState = new Set(oldState);
        if (newState.has(itemId(item))) {
          newState.delete(itemId(item));
          setAllFilteredItemsSelected(false);
        } else {
          newState.add(itemId(item));
        }
        return newState;
      });
      if (!holdingShift) {
        return;
      }
      const lastSelectedItemIndex = items.findIndex((x) => {
        return itemId(x) === lastSelectedItemId;
      });
      const toggledItemIndex = items.findIndex((x) => {
        return itemId(x) === itemId(item);
      });
      if (lastSelectedItemIndex === -1 || toggledItemIndex === -1) {
        return;
      }
      const min = Math.min(lastSelectedItemIndex, toggledItemIndex);
      const max = Math.max(lastSelectedItemIndex, toggledItemIndex);
      const newSelectedItemIds = new Set(selectedItemIds);
      items.slice(min, max + 1).forEach((x) => {
        if (selectedItemIds.has(itemId(item))) {
          newSelectedItemIds.delete(itemId(x));
        } else {
          newSelectedItemIds.add(itemId(x));
        }
      });
      setSelectedItemIds(newSelectedItemIds);
    },
    [itemId, items, lastSelectedItemId, selectedItemIds],
  );
  const toggleAllFilteredItemsSelected = useCallback(() => {
    setAllFilteredItemsSelected(!allFilteredItemsSelected);
    if (allFilteredItemsSelected) {
      setSelectedItemIds(new Set());
    } else {
      setSelectedItemIds(
        new Set(
          items
            .filter((x) => {
              return !(
                multiselect?.disableMultiSelectForItem &&
                multiselect.disableMultiSelectForItem(x)
              );
            })
            .map((x) => itemId(x)),
        ),
      );
    }
  }, [allFilteredItemsSelected, itemId, items, multiselect]);
  const deselectAllSelectedItems = useCallback(() => {
    setSelectedItemIds(new Set());
  }, [setSelectedItemIds]);
  const { width: screenWidth } = useScreenDimensions();
  const columnsToDisplay: K[] = useMemo(
    () =>
      (visibleColumns?.() || allColumns).filter((x) => {
        if (hiddenColumns?.().includes(x)) return false;
        const columnPageMinWidth =
          responsiveness?.[x]?.collapseAtScreenWidth || 0;
        return screenWidth > columnPageMinWidth;
      }),
    [visibleColumns, allColumns, hiddenColumns, responsiveness, screenWidth],
  );
  const showLoadingState = useMemo(
    () => !!loading && allItems.length === 0,
    [allItems.length, loading],
  );
  const showNoSearchResultsEmptyState = useMemo(() => {
    return items.length === 0 && allItems.length !== 0 && !showLoadingState;
  }, [allItems.length, items.length, showLoadingState]);
  const selectedItems = useMemo(
    () => allItems.filter((x) => selectedItemIds.has(itemId(x))),
    [allItems, itemId, selectedItemIds],
  );
  return (
    <AutoLayout
      direction={"vertical"}
      style={{ alignSelf: "stretch" }}
      data-testid={dataTestId || "table"}
    >
      {(showCount || fuseSearchConfig || rightAction || exportConfig) && (
        <AutoLayout
          direction={"horizontal"}
          alignSelf={"stretch"}
          spacingMode={"space-between"}
          alignmentVertical={"center"}
        >
          <AutoLayout alignmentVertical="center">
            {title && (
              <AutoLayout marginRight={8}>
                {typeof title === "string" ? (
                  <Text type={"H4"} fontWeight={"SemiBold"}>
                    {title}
                  </Text>
                ) : (
                  title
                )}
              </AutoLayout>
            )}
            {showLoadingState ? (
              <div />
            ) : title && showCount ? (
              <TableItemCount
                count={items.length}
                totalCount={allItems.length}
              />
            ) : null}
          </AutoLayout>
          <AutoLayout direction={"horizontal"} spaceBetweenItems={8}>
            {fuseSearchConfig ? (
              <AutoLayout style={{ maxWidth: 200, width: 200 }}>
                <TextField
                  placeholder="Search..."
                  text={searchValue}
                  leftIcon="search"
                  onTextChange={(search) => setSearchValue(search)}
                />
              </AutoLayout>
            ) : null}
            {rightAction}
            {exportConfig && (
              <Button
                variant="Outline"
                text="Export"
                styleDeclaration={styles.exportButton}
                onClick={() => {
                  const columns = exportConfig.exportColumns();
                  downloadCSV(
                    exportConfig.title,
                    columns.map((c) => columnTitles?.[c] || c),
                    items.map((i) => {
                      return columns.map((c) => exportConfig.exportFns[c](i));
                    }),
                  );
                }}
              />
            )}
          </AutoLayout>
        </AutoLayout>
      )}
      <AutoLayout direction={"vertical"} style={{ alignSelf: "stretch" }}>
        {columnTitles && (
          <TableHeaderRow
            columns={columnsToDisplay}
            columnTitles={columnTitles}
            responsiveness={responsiveness}
            multiselect={multiselect}
            selectedSort={selectedSort}
            setSelectedSort={setSelectedSort}
            allFilteredItemsSelected={allFilteredItemsSelected}
            toggleAllFilteredItemsSelected={toggleAllFilteredItemsSelected}
            includeOverflowMenuColumn={!!overflowMenu}
            stickyPositioning={headerStickyPositioning}
            isSkeletonState={showLoadingState}
            disabledSorts={disabledSorts}
            headerCellStyles={headerCellStyles}
            onColumnHeaderClick={onColumnHeaderClick}
            activeColumn={activeColumn}
            headerHeight={headerHeight}
          />
        )}
        <ViewportList
          items={items}
          itemMinSize={typeof rowHeight === "number" ? rowHeight : undefined}
        >
          {(item) => {
            return (
              <TableContentRow
                key={itemId(item)}
                item={item}
                columns={columnsToDisplay}
                renderFns={renderFns}
                multiselect={multiselect}
                responsiveness={responsiveness}
                isSelected={selectedItemIds.has(itemId(item))}
                toggleItemSelected={toggleItemSelected}
                rowStyles={rowStyles}
                rowHeight={rowHeight}
                onRowClick={onRowClick}
                rowClickLink={rowClickLink}
                disableRowClickOnColumns={disableRowClickOnColumns}
                overflowMenu={overflowMenu}
                activeColumn={activeColumn}
              />
            );
          }}
        </ViewportList>
        {showNoSearchResultsEmptyState && (
          <AutoLayout
            alignSelf={"stretch"}
            paddingTop={80}
            alignmentHorizontal={"center"}
          >
            <Text
              type={"P2"}
              fontWeight={"Medium"}
              color={deprecatedTones.gray6}
            >
              No results
            </Text>
          </AutoLayout>
        )}
        {showLoadingState && (
          <TableSkeletonState
            allColumns={allColumns}
            columnsToDisplay={columnsToDisplay}
            overflowMenu={overflowMenu}
            multiselect={multiselect}
            responsiveness={responsiveness}
            rowHeight={rowHeight}
            rowStyles={rowStyles}
          />
        )}
        {!showLoadingState && allItems.length === 0 && (
          <AutoLayout
            alignSelf={"stretch"}
            paddingTop={emptyStatePaddingTop}
            alignmentHorizontal={"center"}
          >
            {!noDataEmptyState || typeof noDataEmptyState === "string" ? (
              <Text
                type={"P2"}
                fontWeight={"Medium"}
                color={deprecatedTones.gray6}
              >
                {noDataEmptyState || "Nothing to display"}
              </Text>
            ) : (
              noDataEmptyState
            )}
          </AutoLayout>
        )}
        {multiselect && (
          <MultiSelectBanner
            numSelected={selectedItemIds.size}
            onDeselectAll={deselectAllSelectedItems}
            actions={multiselect.actions(
              selectedItems,
              deselectAllSelectedItems,
            )}
            destroyLabel={multiselect.destroyLabel}
            onDestroy={
              multiselect.onDestroy
                ? () => {
                    if (multiselect.onDestroy) {
                      multiselect.onDestroy(
                        selectedItems,
                        deselectAllSelectedItems,
                      );
                    }
                  }
                : undefined
            }
          />
        )}
      </AutoLayout>
    </AutoLayout>
  );
}

export type RenderFns<T, K extends string> = Record<
  K,
  (item: T) => React.ReactNode
>;

export type HeaderCellStyles<K extends string> = Record<
  K,
  { inner?: CSSProperties; outer?: CSSProperties }
>;

export type ExportConfig<T, K extends string> = {
  title: string;
  exportColumns: () => readonly K[];
  exportColumnTitles?: Record<K, string>;
  exportFns: Record<K, (item: T) => CsvCell>;
};

export type HeaderTooltipCopy<K extends string> = Partial<Record<K, string>>;

export type SortConfig<K extends string> = {
  column: K;
  descending: boolean;
};

export type ResponsivenessConfigs<K extends string> = Partial<
  Record<K, ResponsivenessConfig>
>;

export type SortingFns<T, K extends string> = Partial<
  Record<K, (item: T) => SortFnReturnValue>
>;

type SortFnReturnValue = string | number | undefined | null | boolean;

export type ResponsivenessConfig = {
  width?: number;
  minWidth?: number;
  flex?: CSSProperties["flex"];
  collapseAtScreenWidth?: number;
};
export type MultiSelectConfig<T> = {
  checkboxColumnWidth: number;
  renderAvatarFn: (item: T) => React.ReactNode;
  actions: (selectedItems: T[], deselectAll: () => void) => MultiSelectAction[];
  disableMultiSelectForItem?: (item: T) => boolean;
  onDestroy?: (selectedItems: T[], deselectAll: () => void) => void;
  hasDestroyPermission?: boolean;
  destroyLabel?: string;
};

export type RowConfig<T> = {
  onClick?: (item: T) => void;
  height?: "42px" | "56px";
  styles?: CSSProperties;
};

export type KeyType = string | number;

export type FuseSearchConfig<T> = {
  threshold?: number;
  keys: FuseSearchKey<T>[];
};

export type FuseSearchKey<T> = {
  name: string;
  getFn: (item: T) => string;
};

export const TableItemCount: FC<{
  loading?: boolean;
  count?: number;
  totalCount?: number;
}> = (props) => {
  const textValue = useMemo(() => {
    if (props.loading && !props.totalCount) {
      return "";
    }
    if (props.count !== props.totalCount) {
      return `${props.count} of ${props.totalCount}`;
    } else {
      return props.totalCount;
    }
  }, [props.count, props.loading, props.totalCount]);
  return (
    <AutoLayout
      alignmentVertical={"center"}
      alignmentHorizontal={"center"}
      height={20}
      padding={4}
      style={{
        borderRadius: 6,
        backgroundColor: deprecatedTones.gray4Alpha,
        minWidth: 16,
        paddingLeft: 4,
        paddingRight: 4,
      }}
    >
      <Text type={"P3"} fontWeight={"SemiBold"} color={deprecatedTones.gray8}>
        {textValue}
      </Text>
    </AutoLayout>
  );
};

const styles = StyleSheet.create({
  exportButton: {
    borderRadius: 8,
  },
});
