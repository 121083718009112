import { css, StyleDeclaration, StyleSheet } from "aphrodite";
import { CSSProperties, ReactElement, useMemo, useState } from "react";
import * as Select from "@radix-ui/react-select";
import useRefHeightAndWidth from "@src/hooks/useRefHeightAndWidth";
import DeprecatedIcon, {
  DeprecatedIconType,
} from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Text, { TextFontWeight } from "@ui/text";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import {
  curves,
  durations,
  properties,
} from "@src/deprecatedDesignSystem/styles/transitions";
import Checkbox from "./Checkbox";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Fuse from "fuse.js";
import TextField from "@src/deprecatedDesignSystem/components/TextField";
import { shadows } from "../styles/shadows";

export interface Option<T extends string> {
  value: T;
  label: string;
}

interface Props<T extends string> {
  values: T[];
  options: Option<T>[];
  placeholder: string;
  searchEnabled?: boolean;
  onValueChange: (newValues: T[]) => void;
  icon?: DeprecatedIconType;
  label?: string;
  dropdownHeight?: number;
  fontWeight?: TextFontWeight;
  style?: CSSProperties;
  styleDeclaration?: StyleDeclaration;
  dataTestId?: string;
}

function MultiSelectField<T extends string>({
  values,
  options,
  placeholder,
  searchEnabled,
  onValueChange,
  icon,
  label,
  dropdownHeight,
  fontWeight,
  style,
  styleDeclaration,
  dataTestId,
}: Props<T>): ReactElement {
  const [ref, dimensions] = useRefHeightAndWidth();
  const valuesAsString = useMemo(() => {
    return options
      .filter((option) => values.includes(option.value))
      .map((option) => option.label)
      .join(", ");
  }, [values, options]);
  const [searchValue, setSearchValue] = useState("");
  const optionsToDisplay = useMemo(() => {
    if (!searchEnabled || searchValue === "") {
      return options;
    }
    return new Fuse(options, {
      keys: ["label"],
      threshold: 0.3,
    })
      .search(searchValue)
      .map((item) => item.item);
  }, [options, searchEnabled, searchValue]);
  return (
    <div className={css(styleDeclaration)} style={style}>
      {label && (
        <Text
          type="P3"
          fontWeight="SemiBold"
          inline
          style={{ marginBottom: 4 }}
        >
          {label}
        </Text>
      )}
      <Select.Root value={""}>
        <Select.Trigger asChild>
          <div
            ref={ref}
            className={css(styles.multiSelectContainer)}
            data-testid={dataTestId || "multi-select-field-input"}
          >
            <div className={css(styles.valueContainer)}>
              {icon && (
                <DeprecatedIcon type={icon} color={deprecatedTones.gray7} />
              )}
              <Text
                type="P2"
                color={
                  values.length > 0
                    ? deprecatedTones.black
                    : deprecatedTones.gray5
                }
                fontWeight={fontWeight}
                multiline={true}
              >
                {values.length > 0 ? valuesAsString : placeholder}
              </Text>
            </div>
            <DeprecatedIcon type="chevron-down" color={deprecatedTones.gray5} />
          </div>
        </Select.Trigger>
        <Select.Content style={{ zIndex: 1000 }}>
          <div
            className={css(styles.contentContainer)}
            style={{
              width: dimensions.width || undefined,
              maxWidth: dimensions.width || undefined,
            }}
          >
            {searchEnabled && (
              <AutoLayout paddingHorizontal={8} paddingTop={8}>
                <TextField
                  leftIcon={"search"}
                  placeholder={"Search"}
                  text={searchValue}
                  onTextChange={setSearchValue}
                  autoFocus={true}
                />
              </AutoLayout>
            )}
            {options.length === 0 && (
              <AutoLayout alignmentVertical={"center"} height={40}>
                <Text
                  type={"P3"}
                  fontWeight={"SemiBold"}
                  color={deprecatedTones.gray7}
                  style={{
                    marginLeft: 8,
                  }}
                >
                  No options
                </Text>
              </AutoLayout>
            )}
            {options.length > 0 &&
              optionsToDisplay.length === 0 &&
              searchValue && (
                <Text
                  type={"P3"}
                  fontWeight={"SemiBold"}
                  color={deprecatedTones.gray7}
                  style={{
                    marginLeft: 8,
                  }}
                >
                  No results
                </Text>
              )}
            <AutoLayout
              direction={"vertical"}
              alignSelf={"stretch"}
              className={css(styles.optionsContainer)}
              style={{ maxHeight: `${dropdownHeight}px` }}
            >
              {optionsToDisplay.map((option) => {
                return (
                  <AutoLayout
                    alignSelf={"stretch"}
                    key={option.value}
                    className={css(styles.optionContainer)}
                    data-testid={"multi-select-option"}
                    onClick={() => {
                      if (values.includes(option.value)) {
                        onValueChange(values.filter((x) => x !== option.value));
                      } else {
                        onValueChange([...values, option.value]);
                      }
                    }}
                  >
                    <Checkbox
                      checked={values.includes(option.value)}
                      onChange={() => {}}
                      style={{ position: "relative", top: 1.5 }}
                    />
                    <Text type="P2" fontWeight={fontWeight || "SemiBold"}>
                      {option.label}
                    </Text>
                  </AutoLayout>
                );
              })}
            </AutoLayout>
          </div>
        </Select.Content>
      </Select.Root>
    </div>
  );
}

const styles = StyleSheet.create({
  multiSelectContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "7px 6px",
    backgroundColor: deprecatedTones.white,
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
    borderRadius: 8,
    cursor: "pointer",
  },
  valueContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    marginRight: 2,
    marginLeft: 4,
  },
  contentContainer: {
    overflow: "hidden",
    borderRadius: 8,
    backgroundColor: deprecatedTones.white,
    boxShadow: shadows.dropdownShadow,
  },
  optionsContainer: {
    padding: "6px 0",
    overflowY: "auto",
    borderRadius: 12,
  },
  optionContainer: {
    display: "flex",
    gap: 8,
    padding: 8,
    backgroundColor: deprecatedTones.white,
    transitionDuration: durations.S015,
    transitionProperty: properties.backgroundColor,
    transitionTimingFunction: curves.bezier,
    ":focus": {
      border: "none",
      outline: "none",
      backgroundColor: deprecatedTones.gray4Alpha,
    },
    ":hover": {
      cursor: "pointer",
      border: "none",
      outline: "none",
      backgroundColor: deprecatedTones.gray4Alpha,
    },
  },
});

export default MultiSelectField;
