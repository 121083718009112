import { FC } from "react";
import {
  DeprecatedColor,
  DeprecateTone,
  deprecatedTones,
} from "../styles/deprecatedColors";
import { css, StyleDeclaration, StyleSheet } from "aphrodite";

type Props = {
  color?: DeprecateTone | DeprecatedColor;
  styleDeclaration?: StyleDeclaration;
};

const CircleDivider: FC<Props> = ({
  color = deprecatedTones.gray8,
  styleDeclaration,
}) => {
  return (
    <span
      className={css(styles.container, styleDeclaration)}
      style={{ backgroundColor: color }}
    ></span>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 3,
    height: 3,
    borderRadius: 3,
  },
});

export default CircleDivider;
