export type CsvCell = string | number | boolean | null | undefined;

export const downloadCSV = (
  title: string,
  header: readonly CsvCell[],
  rows: CsvCell[][],
): void => {
  const rowsStrings = rows.map((r) =>
    r
      .map((c) =>
        typeof c === "string"
          ? c.replaceAll(",", "").replace(/[\n\r]/g, "")
          : c,
      )
      .join(","),
  );
  const headerString = header.join(",");
  openDownload(generateCSVDataURIWithHeader(headerString, rowsStrings), title);
};

export const generateCSVDataURIWithHeader = (
  header: string,
  rows: string[],
): string => {
  const csv = rows.reduce((csv, row) => `${csv}\n${row}`, header);
  return generateCSVDataURI(csv);
};

export const generateCSVDataURI = (data: string): string => {
  return `data:text/csv;charset=utf-8,${encodeURIComponent(data)}`;
};

export function openDownload(data: string, title: string): void {
  const link = document.createElement("a");
  link.setAttribute("href", data);
  link.setAttribute("download", title);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function sanitizeCsvHeader(inputString: string): string {
  return inputString.replace(/[^a-zA-Z0-9\s]/g, "");
}
