import React, { CSSProperties, FC, useMemo } from "react";
import { css, StyleDeclaration, StyleSheet } from "aphrodite";
import Text from "@ui/text";
import NextLink from "next/link";
import { Route } from "nextjs-routes";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { isNotNullOrUndefined } from "@utils/typeguards";

export type TableContentCellProps = {
  children?: React.ReactNode;
  width?: number;
  minWidth?: number;
  alignContent?: "Left" | "Right" | "Center";
  styleDeclaration?: StyleDeclaration;
  innerStyleDeclaration?: StyleDeclaration;
  innerStyle?: CSSProperties;
  onClick?: () => void;
  linkUrl?: Route | null | undefined;
  flex?: CSSProperties["flex"];
  style?: CSSProperties;
  wrapHeight?: boolean;
  defaultEmptyTextToDash?: boolean;
  active?: boolean;
};

const PADDING_LEFT = 8;

const TableContentCell: FC<TableContentCellProps> = (
  {
    flex = 1,
    children,
    width,
    minWidth,
    alignContent = "Left",
    styleDeclaration,
    innerStyleDeclaration,
    style,
    onClick,
    linkUrl,
    wrapHeight,
    defaultEmptyTextToDash = true,
    active,
    innerStyle,
  },
  ref,
) => {
  const childrenToRender = useMemo(() => {
    if (
      typeof children === "string" ||
      typeof children === "number" ||
      children === undefined ||
      children === null ||
      children === false
    ) {
      return (
        <Text type={"P2"} ellipsis ellipsisParentFlexDirection={"row"}>
          {isNotNullOrUndefined(children)
            ? children
            : defaultEmptyTextToDash
              ? "-"
              : undefined}
        </Text>
      );
    }
    return children;
  }, [children, defaultEmptyTextToDash]);
  const innerPositioningStyles: CSSProperties = useMemo(
    () => ({
      position: wrapHeight ? undefined : "absolute",
      overflow: "hidden",
      minWidth: 0,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      flexDirection: "row",
      alignSelf: "stretch",
      flex: 1,
      alignItems: "center",
      paddingLeft: PADDING_LEFT,
      ...innerStyle,
    }),
    [wrapHeight, innerStyle],
  );
  const innerComponent = useMemo(() => {
    if (linkUrl) {
      return (
        <NextLink
          href={linkUrl}
          id={"TableContentCell-inner"}
          style={{
            ...innerPositioningStyles,
            textDecoration: "none",
          }}
          className={css(
            alignContent === "Left" && styles.justifyLeft,
            alignContent === "Center" && styles.justifyCenter,
            alignContent === "Right" && styles.justifyRight,
            active && styles.active,
            innerStyleDeclaration,
          )}
        >
          {childrenToRender}
        </NextLink>
      );
    } else {
      return (
        <div
          id={"TableContentCell-inner"}
          style={innerPositioningStyles}
          className={css(
            alignContent === "Left" && styles.justifyLeft,
            alignContent === "Center" && styles.justifyCenter,
            alignContent === "Right" && styles.justifyRight,
            active && styles.active,
            innerStyleDeclaration,
          )}
        >
          {childrenToRender}
        </div>
      );
    }
  }, [
    alignContent,
    childrenToRender,
    innerPositioningStyles,
    innerStyleDeclaration,
    linkUrl,
    active,
  ]);
  return (
    <div
      ref={ref}
      className={css(styles.container, styleDeclaration)}
      onClick={onClick}
      style={{
        position: "relative",
        alignSelf: wrapHeight ? "center" : "stretch",
        flex: flex,
        width: width ? width + PADDING_LEFT : undefined,
        minWidth: minWidth ? minWidth + PADDING_LEFT : PADDING_LEFT,
        maxWidth: width ? width + PADDING_LEFT : undefined,
        ...style,
      }}
    >
      {innerComponent}
    </div>
  );
};

const styles = StyleSheet.create({
  container: {},
  active: {
    backgroundColor: deprecatedTones.yellow2,
  },
  justifyLeft: {
    justifyContent: "flex-start",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  justifyRight: {
    justifyContent: "flex-end",
  },
  height48px: {
    height: 48,
    maxHeight: 48,
  },
  height56px: {
    height: 56,
    maxHeight: 56,
  },
});

// @ts-ignore
export default React.forwardRef(TableContentCell);
