import { FC } from "react";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import { DeprecatedIconType } from "@src/deprecatedDesignSystem/deprecatedIcons";
import { css, StyleDeclaration, StyleSheet } from "aphrodite";
import {
  DeprecatedToneName,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";

type Props = {
  icon: DeprecatedIconType;
  size: "24px" | "32px" | "40px";
  color?: DeprecatedToneName;
  styleDeclaration?: StyleDeclaration;
};

type ColorValues = {
  backgroundColor: string;
  color: string;
};
const toneNameToValues: { [key in DeprecatedToneName]: ColorValues } = {
  blue: {
    backgroundColor: deprecatedTones.blue2,
    color: deprecatedTones.blue12,
  },
  purple: {
    backgroundColor: deprecatedTones.purple2,
    color: deprecatedTones.purple12,
  },
  orange: {
    backgroundColor: deprecatedTones.orange2,
    color: deprecatedTones.orange12,
  },
  yellow: {
    backgroundColor: deprecatedTones.yellow2,
    color: deprecatedTones.yellow12,
  },
  gray: {
    backgroundColor: deprecatedTones.gray2,
    color: deprecatedTones.gray12,
  },
  green: {
    backgroundColor: deprecatedTones.green2,
    color: deprecatedTones.green12,
  },
  red: {
    backgroundColor: deprecatedTones.red2,
    color: deprecatedTones.red12,
  },
};

const IconAvatar: FC<Props> = ({
  icon,
  size,
  color = "blue",
  styleDeclaration,
}) => {
  const colorValues = toneNameToValues[color];
  return (
    <span
      className={css(styles.container, styleDeclaration)}
      style={{
        width: size,
        height: size,
        backgroundColor: colorValues.backgroundColor,
      }}
    >
      <DeprecatedIcon type={icon} color={colorValues.color} />
    </span>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 40,
  },
});

export default IconAvatar;
