import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import { CoverImageFragmentDoc, TranslationSetFragmentDoc, MediaFragmentDoc, ContentTagMembershipFragmentDoc, ContentQrCodeFragmentDoc, ContentSharingPermissionFragmentDoc, AutomationFragmentDoc, ImageUrlsFragmentDoc, AiGenerateModuleRequestFragmentDoc, UserSelectionFragmentDoc, PathInstanceThinFragmentDoc, BlastSentRecordFragmentDoc } from '../../../fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssignLibraryItemModal_LibraryItemQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type AssignLibraryItemModal_LibraryItemQuery = { __typename?: 'Query', LibraryItem?: { __typename?: 'LibraryItem', id: string, type: Types.LibraryItemType, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null, name: { __typename?: 'TranslationSet', en: string }, trainingResource?: { __typename?: 'TrainingResource', media: { __typename?: 'Media', id: number, loopVideo: boolean, hideVideoControls: boolean, unoptimizedUrl?: string | null, createdAt: string, updatedAt: string, thumbnailImageUrl?: string | null, name?: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } | null, description?: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } | null, mediaUrls: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> }, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null, trainingResource?: { __typename?: 'TrainingResource', id: string } | null } } | null } | null };

export type AssignLibraryItemModal_ExistingAssignmentsQueryVariables = Types.Exact<{
  input: Types.AssignmentsInput;
}>;


export type AssignLibraryItemModal_ExistingAssignmentsQuery = { __typename?: 'Query', Assignments: { __typename?: 'Assignments', objects: Array<{ __typename?: 'Assignment', id: string, status: AssignmentStatus, user: { __typename?: 'Employee', id: number } }> } };


export const AssignLibraryItemModal_LibraryItemDocument = gql`
    query AssignLibraryItemModal_LibraryItem($id: UUID!) {
  LibraryItem(id: $id) {
    id
    coverImage {
      ...CoverImage
    }
    name {
      en
    }
    type
    trainingResource {
      media {
        ...Media
      }
    }
  }
}
    ${CoverImageFragmentDoc}
${MediaFragmentDoc}`;

/**
 * __useAssignLibraryItemModal_LibraryItemQuery__
 *
 * To run a query within a React component, call `useAssignLibraryItemModal_LibraryItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignLibraryItemModal_LibraryItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignLibraryItemModal_LibraryItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssignLibraryItemModal_LibraryItemQuery(baseOptions: Apollo.QueryHookOptions<AssignLibraryItemModal_LibraryItemQuery, AssignLibraryItemModal_LibraryItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssignLibraryItemModal_LibraryItemQuery, AssignLibraryItemModal_LibraryItemQueryVariables>(AssignLibraryItemModal_LibraryItemDocument, options);
      }
export function useAssignLibraryItemModal_LibraryItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssignLibraryItemModal_LibraryItemQuery, AssignLibraryItemModal_LibraryItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssignLibraryItemModal_LibraryItemQuery, AssignLibraryItemModal_LibraryItemQueryVariables>(AssignLibraryItemModal_LibraryItemDocument, options);
        }
export type AssignLibraryItemModal_LibraryItemQueryHookResult = ReturnType<typeof useAssignLibraryItemModal_LibraryItemQuery>;
export type AssignLibraryItemModal_LibraryItemLazyQueryHookResult = ReturnType<typeof useAssignLibraryItemModal_LibraryItemLazyQuery>;
export type AssignLibraryItemModal_LibraryItemQueryResult = Apollo.QueryResult<AssignLibraryItemModal_LibraryItemQuery, AssignLibraryItemModal_LibraryItemQueryVariables>;
export const AssignLibraryItemModal_ExistingAssignmentsDocument = gql`
    query AssignLibraryItemModal_ExistingAssignments($input: AssignmentsInput!) {
  Assignments(input: $input) {
    objects {
      id
      user {
        id
      }
      status
    }
  }
}
    `;

/**
 * __useAssignLibraryItemModal_ExistingAssignmentsQuery__
 *
 * To run a query within a React component, call `useAssignLibraryItemModal_ExistingAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignLibraryItemModal_ExistingAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignLibraryItemModal_ExistingAssignmentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignLibraryItemModal_ExistingAssignmentsQuery(baseOptions: Apollo.QueryHookOptions<AssignLibraryItemModal_ExistingAssignmentsQuery, AssignLibraryItemModal_ExistingAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssignLibraryItemModal_ExistingAssignmentsQuery, AssignLibraryItemModal_ExistingAssignmentsQueryVariables>(AssignLibraryItemModal_ExistingAssignmentsDocument, options);
      }
export function useAssignLibraryItemModal_ExistingAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssignLibraryItemModal_ExistingAssignmentsQuery, AssignLibraryItemModal_ExistingAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssignLibraryItemModal_ExistingAssignmentsQuery, AssignLibraryItemModal_ExistingAssignmentsQueryVariables>(AssignLibraryItemModal_ExistingAssignmentsDocument, options);
        }
export type AssignLibraryItemModal_ExistingAssignmentsQueryHookResult = ReturnType<typeof useAssignLibraryItemModal_ExistingAssignmentsQuery>;
export type AssignLibraryItemModal_ExistingAssignmentsLazyQueryHookResult = ReturnType<typeof useAssignLibraryItemModal_ExistingAssignmentsLazyQuery>;
export type AssignLibraryItemModal_ExistingAssignmentsQueryResult = Apollo.QueryResult<AssignLibraryItemModal_ExistingAssignmentsQuery, AssignLibraryItemModal_ExistingAssignmentsQueryVariables>;