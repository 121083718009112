import React, { CSSProperties } from "react";
import DelayedSkeleton from "@components/ui/DelayedSkeleton";
import { TableContentRow } from "@src/deprecatedDesignSystem/components/table/TableContentRow";
import {
  MultiSelectConfig,
  RenderFns,
  ResponsivenessConfigs,
  RowHeight,
} from "@src/deprecatedDesignSystem/components/table/Table";

type GhostStateRowItem = {
  id: number;
};
type Props<T, K extends string> = {
  allColumns: K[];
  columnsToDisplay: K[];
  multiselect?: MultiSelectConfig<T>;
  responsiveness?: ResponsivenessConfigs<K>;
  rowStyles?: CSSProperties;
  rowHeight: RowHeight;
  overflowMenu?: (item: T) => React.ReactNode;
};

function TableSkeletonState<T, K extends string>({
  multiselect,
  allColumns,
  columnsToDisplay,
  responsiveness,
  rowStyles,
  rowHeight,
  overflowMenu,
}: Props<T, K>): JSX.Element {
  return (
    <>
      {[1, 2, 3].map((x) => {
        let ghostMultiSelectConfig:
          | MultiSelectConfig<GhostStateRowItem>
          | undefined = undefined;
        if (multiselect) {
          ghostMultiSelectConfig = {
            ...multiselect,
            onDestroy: undefined,
            actions: () => [],
            renderAvatarFn: () => (
              <DelayedSkeleton
                height={18}
                width={multiselect.checkboxColumnWidth / 2}
              />
            ),
            disableMultiSelectForItem: () => false,
          };
        }
        // @ts-ignore
        const ghostRenderFunctions: RenderFns<GhostStateRowItem, K> =
          Object.fromEntries(
            allColumns.map((k) => [
              k,
              () => (
                <DelayedSkeleton
                  height={18}
                  width={(responsiveness && responsiveness[k]?.width) || 100}
                />
              ),
            ]),
          );
        return (
          <TableContentRow<GhostStateRowItem, K>
            key={x}
            item={{ id: x }}
            columns={columnsToDisplay}
            renderFns={ghostRenderFunctions}
            multiselect={ghostMultiSelectConfig}
            responsiveness={responsiveness}
            rowStyles={rowStyles}
            rowHeight={rowHeight}
            overflowMenu={overflowMenu ? () => <div /> : undefined}
            isSkeletonState={true}
          />
        );
      })}
    </>
  );
}

export default TableSkeletonState;
