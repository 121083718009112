import { StyleSheet as Aphrodite } from "aphrodite";

const { StyleSheet, css } = Aphrodite.extend([
  {
    selectorHandler: (selector, baseSelector, generateSubtreeStyles) => {
      if (selector[0] === " ") {
        const tag = selector.slice(1);
        const nestedTag = generateSubtreeStyles(`${baseSelector} ${tag}`);
        return nestedTag;
      }
      return null;
    },
  },
]);

export { StyleSheet, css };
