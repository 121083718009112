import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssignLibraryItemsMutationVariables = Types.Exact<{
  input: Types.AssignLibraryItemsInput;
}>;


export type AssignLibraryItemsMutation = { __typename?: 'Mutation', assignLibraryItems: { __typename?: 'BaseMutationResult', success: boolean, error?: { __typename?: 'Error', code: string } | null } };


export const AssignLibraryItemsDocument = gql`
    mutation AssignLibraryItems($input: AssignLibraryItemsInput!) {
  assignLibraryItems(input: $input) {
    success
    error {
      code
    }
  }
}
    `;
export type AssignLibraryItemsMutationFn = Apollo.MutationFunction<AssignLibraryItemsMutation, AssignLibraryItemsMutationVariables>;

/**
 * __useAssignLibraryItemsMutation__
 *
 * To run a mutation, you first call `useAssignLibraryItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignLibraryItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignLibraryItemsMutation, { data, loading, error }] = useAssignLibraryItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignLibraryItemsMutation(baseOptions?: Apollo.MutationHookOptions<AssignLibraryItemsMutation, AssignLibraryItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignLibraryItemsMutation, AssignLibraryItemsMutationVariables>(AssignLibraryItemsDocument, options);
      }
export type AssignLibraryItemsMutationHookResult = ReturnType<typeof useAssignLibraryItemsMutation>;
export type AssignLibraryItemsMutationResult = Apollo.MutationResult<AssignLibraryItemsMutation>;
export type AssignLibraryItemsMutationOptions = Apollo.BaseMutationOptions<AssignLibraryItemsMutation, AssignLibraryItemsMutationVariables>;