import { MyTrainingPathContentMembershipInstanceFragment } from "@src/components/my-training/courseInstance/contexts/PathInstanceContextProvider.generated";
import { ContentMembershipInstanceSection } from "@src/components/my-training/types";
import { RoleDetailUserSelectionFragment } from "@src/components/roles/role-detail/RoleDetail.generated";
import { LocationGroupUserSelectionFragment } from "@src/contexts/LocationGroupDetailContext/LocationGroupDetailContext.generated";
import { UserSelectionFragment } from "@src/fragments.generated";
import { UserSelectionInput } from "@src/types.generated";
import { listToReadableString } from "@utils/strings";

export const getRoleString = (
  userSelection: Pick<
    RoleDetailUserSelectionFragment | LocationGroupUserSelectionFragment,
    "allManagers" | "allTrainees" | "allAdmins" | "roleMemberships"
  >,
): string => {
  if (
    userSelection.allTrainees &&
    userSelection.allManagers &&
    userSelection.allAdmins
  ) {
    return "Everyone";
  }
  let parts = [];
  if (userSelection.allTrainees) {
    parts.push("All Trainees");
  }
  if (userSelection.allManagers) {
    parts.push("All Managers");
  }
  if (userSelection.allAdmins) {
    parts.push("All Admins");
  }
  if (userSelection.roleMemberships.length > 0) {
    const roleNames = userSelection.roleMemberships.map((ra) => ra.role.name);
    parts = parts.concat(roleNames);
  }
  if (parts.length === 0) {
    return "Everyone";
  }
  return listToReadableString(parts);
};

export const getLocationString = (
  userSelection: Pick<
    RoleDetailUserSelectionFragment | LocationGroupUserSelectionFragment,
    "allLocations" | "locationMemberships" | "locationGroupMemberships"
  >,
): string => {
  if (userSelection.allLocations) {
    return "All Locations";
  }
  let parts: string[] = [];
  if (userSelection.locationMemberships.length > 0) {
    const locationNames = userSelection.locationMemberships.map(
      (la) => la.location.name,
    );
    parts = parts.concat(locationNames);
  }
  if (userSelection.locationGroupMemberships.length > 0) {
    const locationGroupNames = userSelection.locationGroupMemberships.map(
      (la) => la.locationGroup.name,
    );
    parts = parts.concat(locationGroupNames);
  }
  if (parts.length === 0) {
    return "";
  }
  return listToReadableString(parts);
};

export const getAutomationString = (
  userSelection:
    | RoleDetailUserSelectionFragment
    | LocationGroupUserSelectionFragment,
): string => {
  const roleString = getRoleString(userSelection);
  const locationString = getLocationString(userSelection);
  if (locationString) {
    return `${roleString} in ${locationString}`;
  }
  return roleString;
};

export const isUserSelectionEmpty = (
  userSelection:
    | RoleDetailUserSelectionFragment
    | LocationGroupUserSelectionFragment,
): boolean => {
  return (
    userSelection.allTrainees == false &&
    userSelection.allManagers == false &&
    userSelection.allAdmins == false &&
    userSelection.allLocations == false &&
    userSelection.locationMemberships.length === 0 &&
    userSelection.roleMemberships.length === 0 &&
    userSelection.locationGroupMemberships.length === 0 &&
    userSelection.roleGroupMemberships.length === 0
  );
};

export const userSelectionToUserSelectionInput = (
  userSelection: UserSelectionFragment,
): UserSelectionInput => {
  const input: UserSelectionInput = { ...userSelection };
  input.locationGroupIds = userSelection.locationGroupMemberships.map(
    (x) => x.locationGroup.id,
  );
  input.roleIds = userSelection.roleMemberships.map((x) => x.role.id);
  input.locationIds = userSelection.locationMemberships.map(
    (x) => x.location.id,
  );
  input.roleGroupIds = userSelection.roleGroupMemberships.map(
    (x) => x.roleGroup.id,
  );

  return input;
};

export const isUserSelectionInputEmpty = (
  userSelection: UserSelectionInput,
): boolean => {
  return (
    userSelection.allTrainees == false &&
    userSelection.allManagers == false &&
    userSelection.allAdmins == false &&
    userSelection.allLocations == false &&
    (!userSelection.locationIds || userSelection.locationIds.length === 0) &&
    (!userSelection.roleIds || userSelection.roleIds.length === 0) &&
    (!userSelection.locationGroupIds ||
      userSelection.locationGroupIds.length === 0) &&
    (!userSelection.roleGroupIds || userSelection.roleGroupIds.length === 0)
  );
};

// Copying getSectionGroups from mobile
export const getPathContentMembershipInstanceSections = (
  contentMembership: MyTrainingPathContentMembershipInstanceFragment[],
): ContentMembershipInstanceSection[] => {
  // TODO: Translate "sectionBreak.title.en";
  let section: ContentMembershipInstanceSection = { title: "", contents: [] };
  let sections: ContentMembershipInstanceSection[] = [];

  contentMembership.forEach((cm) => {
    if (cm.sectionBreakInstance) {
      if (section.contents.length > 0) {
        sections = [...sections, section];
      }
      section = {
        title: cm.sectionBreakInstance.sectionBreak.title.myTranslation,
        contents: [],
      };
    } else {
      section.contents.push(cm);
    }
  });
  if (section.contents.length > 0) {
    sections = [...sections, section];
  }

  return sections;
};

export function getMostRecentDate(x: string[]): string | null {
  if (x.length === 1) return x[0];
  return (
    x.sort((a, b) => new Date(b).getTime() - new Date(a).getTime())[0] || null
  );
}
