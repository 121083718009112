import EditUserTypeModal from "@src/components/users/modals/EditUserTypeModal";
import InlineEditButton from "@src/deprecatedDesignSystem/components/InlineEditButton";
import InlineEditField from "@src/deprecatedDesignSystem/components/InlineEditField";
import useActionPermissions from "@hooks/useActionPermissions";
import { useModal } from "@hooks/useModal";
import useUser from "@hooks/useUser";
import { UserType } from "@src/types.generated";
import { FC, useMemo } from "react";
import { UserDetailsAdminEmployeeFragment } from "@src/components/users/user-details/UserDetails.generated";

type Props = {
  user: UserDetailsAdminEmployeeFragment;
};

export const userTypeOptions = [
  { value: UserType.Admin, label: "Admin" },
  { value: UserType.Manager, label: "Manager" },
  { value: UserType.Employee, label: "Trainee" },
];

export const getUserTypeLabel = (userType: UserType): string => {
  return userTypeOptions.filter((option) => option.value === userType)[0].label;
};

const EditUserType: FC<Props> = ({ user }) => {
  const { user: currentUser } = useUser();
  const { userActionPermissions } = useActionPermissions();
  const { showModal } = useModal();
  const canEditUserType = useMemo(() => {
    if (user.id === currentUser?.id) {
      return false;
    }
    if (userActionPermissions?.changeOrgSettings) {
      return true;
    }
    return (
      userActionPermissions?.promoteTrainees &&
      user.userType === UserType.Employee
    );
  }, [
    user.id,
    user.userType,
    currentUser?.id,
    userActionPermissions?.changeOrgSettings,
    userActionPermissions?.promoteTrainees,
  ]);

  return (
    <>
      <InlineEditField
        icon="check-circle"
        label="User type"
        labelStyle={{ width: 160 }}
        valueStyle={{ flex: 1 }}
      >
        {!canEditUserType ? (
          <InlineEditButton
            values={[getUserTypeLabel(user.userType)]}
            loading={user === undefined}
          />
        ) : (
          <InlineEditButton
            values={[getUserTypeLabel(user.userType)]}
            onClick={() => {
              showModal(<EditUserTypeModal user={user} />);
            }}
          />
        )}
      </InlineEditField>
    </>
  );
};

export default EditUserType;
