import React, { useCallback } from "react";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import { css, StyleSheet } from "aphrodite";
import Text from "@ui/text";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui/tooltip";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import TableContentCell from "@src/deprecatedDesignSystem/components/table/TableContentCell";
import {
  HeaderCellStyles,
  HeaderTooltipCopy,
  MultiSelectConfig,
  ResponsivenessConfigs,
  SortConfig,
} from "@src/deprecatedDesignSystem/components/table/Table";
import useHover from "@hooks/useHover";
import { RowHeight } from "@src/deprecatedDesignSystem/components/table/PaginatedTable";

type Props<T, K extends string> = {
  columnTitles: Record<K, string>;
  multiselect?: MultiSelectConfig<T>;
  responsiveness?: ResponsivenessConfigs<K>;
  selectedSort?: SortConfig<K>;
  setSelectedSort?: (x: SortConfig<K>) => void;
  allFilteredItemsSelected?: boolean;
  toggleAllFilteredItemsSelected?: () => void;
  includeOverflowMenuColumn?: boolean;
  stickyPositioning?: number;
  isSkeletonState?: boolean;
  disabledSorts?: K[];
  headerCellStyles?: HeaderCellStyles<K>;
  onColumnHeaderClick?: (column: K) => void;
  headerHeight?: RowHeight;
  showSortDirection?: boolean;
  headerTooltipCopy?: HeaderTooltipCopy<K>;
  multiline?: boolean;
  wrapHeight?: boolean;
  column: K;
};

export function TableHeaderCell<T, K extends string>(
  props: Props<T, K>,
): JSX.Element {
  const {
    column,
    responsiveness,
    headerCellStyles,
    isSkeletonState,
    disabledSorts,
    onColumnHeaderClick,
    columnTitles,
    headerTooltipCopy,
    selectedSort,
    setSelectedSort,
    showSortDirection = true,
    wrapHeight,
    multiline = false,
  } = props;
  const onClickHeader = useCallback(() => {
    if (onColumnHeaderClick) {
      onColumnHeaderClick(column);
    }
    if (!setSelectedSort || disabledSorts?.includes(column)) {
      return;
    }
    if (selectedSort?.column === column) {
      setSelectedSort({
        column: column,
        descending: !selectedSort?.descending,
      });
    } else {
      setSelectedSort({
        column: column,
        descending: true,
      });
    }
  }, [
    onColumnHeaderClick,
    setSelectedSort,
    disabledSorts,
    column,
    selectedSort,
  ]);
  const [isHovered, hoverBind] = useHover();
  const showSortArrow = selectedSort?.column === column && showSortDirection;
  const columnSortDisabled = disabledSorts?.includes(column);
  return (
    <TableContentCell
      key={column}
      width={responsiveness?.[column]?.width}
      minWidth={responsiveness?.[column]?.minWidth}
      flex={responsiveness?.[column]?.flex}
      style={headerCellStyles?.[column].outer}
      innerStyle={headerCellStyles?.[column].inner}
      wrapHeight={wrapHeight}
      onClick={isSkeletonState ? undefined : onClickHeader}
    >
      <div
        className={css(
          styles.nameIconContainer,
          disabledSorts?.includes(column) &&
            !onColumnHeaderClick &&
            styles.disabled,
        )}
        {...hoverBind}
      >
        <Text
          type="P2"
          fontWeight={"Regular"}
          multiline={multiline}
          color={deprecatedTones.gray7}
          styleDeclaration={styles.nameContainer}
          style={{
            flex: 1,
          }}
        >
          {columnTitles[column]}
        </Text>
        {headerTooltipCopy?.[column] && (
          <Tooltip>
            <TooltipTrigger asChild>
              <AutoLayout>
                <DeprecatedIcon type="help-circle" />
              </AutoLayout>
            </TooltipTrigger>
            <TooltipContent>{headerTooltipCopy[column]}</TooltipContent>
          </Tooltip>
        )}
        {showSortArrow && (
          <DeprecatedIcon
            type={selectedSort?.descending ? "arrow-down" : "arrow-up"}
            color={deprecatedTones.black}
            iconSize={16}
            containerSize={24}
            strokeWidth={2}
          />
        )}
        {!showSortArrow && isHovered && !columnSortDisabled && (
          <DeprecatedIcon
            type={"arrow-down"}
            color={deprecatedTones.gray6}
            iconSize={16}
            containerSize={24}
            strokeWidth={2}
          />
        )}
      </div>
    </TableContentCell>
  );
}

export default TableHeaderCell;

const styles = StyleSheet.create({
  nameIconContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  disabled: {
    cursor: "default",
  },
  nameContainer: {
    transform: "translateY(1px)",
  },
});
