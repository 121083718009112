import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import React from "react";
import SimpleDropdown from "@src/components/libraryItemDetailPages/course/SimpleDropdown";
import Button from "@src/deprecatedDesignSystem/components/Button";

type Props = {
  menu: React.ReactNode;
  showOverflow: boolean;
};

function TableRowOverflow({ menu }: Props): JSX.Element {
  return (
    <SimpleDropdown
      radixContentProps={{
        style: {
          padding: 4,
          borderRadius: 12,
        },
      }}
      trigger={
        <Button
          borderRadius="8px"
          variant="No Outline"
          customIconColor={deprecatedTones.black}
          leftIcon="dots-horizontal"
        />
      }
      content={menu}
    />
  );
}

export default TableRowOverflow;
