import { gql } from "@apollo/client";
import { PlanType } from "@src/types.generated";
import usePlan from "./usePlan";
import { useAdminCountQuery } from "./useCanAddMoreAdmins.generated";

const MAX_ADMIN_COUNT_ON_STARTER_PLAN = 2;

export const useCanAddMoreAdmins = (): boolean => {
  const plan = usePlan();
  const { data } = useAdminCountQuery();
  if (!data?.People || !plan) {
    return true;
  }

  const cantAddMoreAdmins = Boolean(
    data?.People &&
      data.People.totalCount >= MAX_ADMIN_COUNT_ON_STARTER_PLAN &&
      plan?.planType === PlanType.Starter,
  );

  return !cantAddMoreAdmins;
};

export default useCanAddMoreAdmins;

gql`
  query AdminCount {
    People(
      input: {
        userTypes: { value: [ADMIN] }
        includeStaffForStaff: { value: false }
      }
    ) {
      totalCount
    }
  }
`;
