import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Text from "@ui/text";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { pluralize } from "@src/utils/strings";
import { StyleSheet } from "aphrodite";
import { FC } from "react";

type Props = {
  creditCostPerAssignment: number;
};

const CreditsChargeWhenStartedBanner: FC<Props> = ({
  creditCostPerAssignment,
}) => {
  return (
    <AutoLayout
      flex={1}
      alignSelf="stretch"
      alignmentVertical="center"
      padding={12}
      spaceBetweenItems={8}
      borderRadius={10}
      styleDeclaration={styles.creditConfirmationBanner}
    >
      <DeprecatedIcon type="coins" color={deprecatedTones.green10} />
      <Text
        multiline
        type="P2"
        fontWeight="Medium"
        color={deprecatedTones.green10}
      >
        You will be charged {creditCostPerAssignment}{" "}
        {pluralize("credit", creditCostPerAssignment)} when someone starts this
        assignment.
      </Text>
    </AutoLayout>
  );
};

const styles = StyleSheet.create({
  creditConfirmationBanner: {
    backgroundColor: deprecatedTones.green1Alpha,
    border: `1px solid ${deprecatedTones.green5Alpha}`,
  },
});

export default CreditsChargeWhenStartedBanner;
