import React, { useCallback } from "react";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import { css, StyleSheet } from "aphrodite";
import TableContentCell from "@src/deprecatedDesignSystem/components/table/TableContentCell";
import Checkbox from "@src/deprecatedDesignSystem/components/Checkbox";
import {
  MultiSelectConfig,
  HeaderCellStyles,
  ResponsivenessConfigs,
  SortConfig,
  HeaderTooltipCopy,
} from "@src/deprecatedDesignSystem/components/table/Table";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import TableHeaderCell from "@src/deprecatedDesignSystem/components/table/TableHeaderCell";
import { RowHeight } from "@src/deprecatedDesignSystem/components/table/PaginatedTable";

type Props<T, K extends string> = {
  columns: K[];
  columnTitles: Record<K, string>;
  multiselect?: MultiSelectConfig<T>;
  responsiveness?: ResponsivenessConfigs<K>;
  selectedSort?: SortConfig<K>;
  setSelectedSort?: (x: SortConfig<K>) => void;
  allFilteredItemsSelected?: boolean;
  toggleAllFilteredItemsSelected?: () => void;
  includeOverflowMenuColumn?: boolean;
  stickyPositioning?: number;
  isSkeletonState?: boolean;
  disabledSorts?: K[];
  headerCellStyles?: HeaderCellStyles<K>;
  onColumnHeaderClick?: (column: K) => void;
  activeColumn?: K;
  headerHeight?: RowHeight;
  showSortDirection?: boolean;
  headerTooltipCopy?: HeaderTooltipCopy<K>;
  multiline?: boolean;
};

export function TableHeaderRow<T, K extends string>(
  props: Props<T, K>,
): JSX.Element {
  const {
    columns,
    multiselect,
    allFilteredItemsSelected,
    toggleAllFilteredItemsSelected,
    includeOverflowMenuColumn,
    stickyPositioning = 0,
    isSkeletonState,
    headerHeight,
  } = props;
  const onClickCheckbox = useCallback(() => {
    if (toggleAllFilteredItemsSelected) {
      toggleAllFilteredItemsSelected();
    }
  }, [toggleAllFilteredItemsSelected]);
  return (
    <AutoLayout direction={"horizontal"} alignSelf={"stretch"}>
      <AutoLayout
        className={css(styles.tableHeaderRowContainer)}
        flex={1}
        style={{
          marginTop: 12,
          paddingBottom: 4,
          height:
            typeof headerHeight === "number" ? headerHeight || 36 : undefined,
          minHeight: 32,
          userSelect: "none",
          top: stickyPositioning,
          borderBottom: "1px solid",
          borderColor: deprecatedTones.gray5Alpha,
        }}
        wrap={"nowrap"}
      >
        {multiselect && (
          <TableContentCell
            width={multiselect.checkboxColumnWidth}
            minWidth={multiselect.checkboxColumnWidth}
            alignContent={"Center"}
          >
            <Checkbox
              checked={allFilteredItemsSelected || false}
              onChange={onClickCheckbox}
              disabled={isSkeletonState}
            />
          </TableContentCell>
        )}
        {columns.map((column) => (
          <TableHeaderCell
            key={column}
            {...props}
            column={column}
            wrapHeight={
              typeof headerHeight === "number"
                ? false
                : headerHeight?.wrap || false
            }
          />
        ))}
        {includeOverflowMenuColumn && (
          <TableContentCell defaultEmptyTextToDash={false} width={33} />
        )}
      </AutoLayout>
    </AutoLayout>
  );
}

const styles = StyleSheet.create({
  tableHeaderRowContainer: {
    position: "sticky",
    zIndex: 9,
    backgroundColor: deprecatedTones.white,
  },
});
