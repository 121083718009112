import React, { FC, useState } from "react";
import { css, StyleSheet } from "aphrodite";
import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Text from "@ui/text";
import Spacer from "@components/ui/Spacer";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import * as Popover from "@radix-ui/react-popover";
import { AnimatePresence, motion } from "framer-motion";
import RadioButton from "@src/deprecatedDesignSystem/components/RadioButton";
import { ContentFilterType } from "@src/components/roles/role-detail/RoleAutomationsTable";

type Props = {
  selectedContentType: ContentFilterType;
  setSelectedContentType: (contentType: ContentFilterType) => void;
};

const ContentTypeFilter: FC<Props> = ({
  selectedContentType,
  setSelectedContentType,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <Popover.Root open={open} onOpenChange={(open) => setOpen(open)}>
      <Popover.Trigger asChild>
        <AutoLayout
          paddingHorizontal={8}
          width={"hug"}
          className={css(styles.container)}
          alignmentVertical={"center"}
          direction={"horizontal"}
          wrap={"nowrap"}
        >
          <>
            <Text
              type={"P3"}
              color={deprecatedTones.gray8}
              style={{ whiteSpace: "nowrap" }}
            >
              Content:
            </Text>
            <Spacer size={4} axis={"horizontal"} />
            <Text
              type={"P3"}
              fontWeight={"SemiBold"}
              style={{ whiteSpace: "nowrap" }}
            >
              {selectedContentType}
            </Text>
          </>
          <Spacer size={6} axis={"horizontal"} />
          <DeprecatedIcon
            type={"chevron-down"}
            color={deprecatedTones.gray5}
            containerSize={20}
            iconSize={24}
          />
        </AutoLayout>
      </Popover.Trigger>
      <AnimatePresence>
        <Popover.Content className={css(styles.dropdown)} align={"end"}>
          <motion.div
            initial={{ scaleX: 1, scaleY: 0.6, opacity: 0 }}
            animate={{
              scaleY: 1,
              opacity: 1,
              transition: { type: "spring", duration: 0.2 },
            }}
            style={{ originY: 0, originX: 1 }}
          >
            <AutoLayout
              direction="vertical"
              alignmentVertical={"center"}
              padding={4}
            >
              {Object.values(ContentFilterType).map((type) => (
                <AutoLayout
                  key={type}
                  height={40}
                  width={"100%"}
                  alignmentHorizontal={"left"}
                  alignmentVertical={"center"}
                  paddingRight={12}
                  paddingLeft={10}
                  styleDeclaration={styles.dropdownItem}
                  onClick={() => {
                    setSelectedContentType(type);
                    setOpen(false);
                  }}
                >
                  <RadioButton
                    checked={type === selectedContentType}
                    onClick={() => {
                      setSelectedContentType(type);
                      setOpen(false);
                    }}
                    label=""
                  />
                  <Spacer size={4} axis={"horizontal"} />
                  <Text
                    type={"P3"}
                    fontWeight={"SemiBold"}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {type}
                  </Text>
                </AutoLayout>
              ))}
            </AutoLayout>
          </motion.div>
        </Popover.Content>
      </AnimatePresence>
    </Popover.Root>
  );
};

export default ContentTypeFilter;

const styles = StyleSheet.create({
  container: {
    border: "1px solid",
    borderColor: deprecatedColors.border,
    height: 32,
    borderRadius: 8,
    fontSize: 14,
    cursor: "pointer",
    position: "relative",
  },
  dropdown: {
    zIndex: 9999,
    height: "100%",
    width: 252,
    backgroundColor: deprecatedTones.white,
    borderRadius: 4,
    boxShadow:
      "0px 0px 12px 6px rgba(142, 145, 146, 0.15), 0px 0px 4px 5px rgba(142, 145, 146, 0.15)",
  },
  dropdownItem: {
    whiteSpace: "nowrap",
    zIndex: 1,
    borderRadius: 8,
    textDecoration: "none",
    cursor: "pointer",
    ":hover": {
      backgroundColor: deprecatedTones.gray4Alpha,
    },
  },
});
