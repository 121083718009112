import { gql, MutationHookOptions, MutationTuple } from "@apollo/client";

import { useTracking } from "@hooks/useTracking";
import {
  AssignLibraryItemsMutation,
  AssignLibraryItemsMutationVariables,
  useAssignLibraryItemsMutation,
} from "@hooks/useAssignLibraryItems.generated";

const useAssignLibraryItems = (
  options?: MutationHookOptions<
    AssignLibraryItemsMutation,
    AssignLibraryItemsMutationVariables
  >,
): MutationTuple<
  AssignLibraryItemsMutation,
  AssignLibraryItemsMutationVariables
> => {
  const { trackEvent } = useTracking();
  return useAssignLibraryItemsMutation({
    ...options,
    onCompleted(data) {
      if (data.assignLibraryItems.success) {
        trackEvent("assigned_content", {
          input: options?.variables?.input,
          response: data.assignLibraryItems,
        });
      }
      if (options?.onCompleted) {
        options.onCompleted(data);
      }
    },
  });
};

gql`
  mutation AssignLibraryItems($input: AssignLibraryItemsInput!) {
    assignLibraryItems(input: $input) {
      success
      error {
        code
      }
    }
  }
`;
export default useAssignLibraryItems;
