import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AdminCountQuery = { __typename?: 'Query', People: { __typename?: 'Employees', totalCount: number } };


export const AdminCountDocument = gql`
    query AdminCount {
  People(
    input: {userTypes: {value: [ADMIN]}, includeStaffForStaff: {value: false}}
  ) {
    totalCount
  }
}
    `;

/**
 * __useAdminCountQuery__
 *
 * To run a query within a React component, call `useAdminCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminCountQuery(baseOptions?: Apollo.QueryHookOptions<AdminCountQuery, AdminCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminCountQuery, AdminCountQueryVariables>(AdminCountDocument, options);
      }
export function useAdminCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminCountQuery, AdminCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminCountQuery, AdminCountQueryVariables>(AdminCountDocument, options);
        }
export type AdminCountQueryHookResult = ReturnType<typeof useAdminCountQuery>;
export type AdminCountLazyQueryHookResult = ReturnType<typeof useAdminCountLazyQuery>;
export type AdminCountQueryResult = Apollo.QueryResult<AdminCountQuery, AdminCountQueryVariables>;