import { FC, useCallback, useMemo } from "react";
import { useModal } from "@src/hooks/useModal";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Button from "@src/deprecatedDesignSystem/components/Button";
import ContentAvatar, {
  LIBRARY_ITEM_TYPE_TO_CONTENT_TYPE,
} from "@src/deprecatedDesignSystem/components/ContentAvatar";
import Footer from "@src/deprecatedDesignSystem/components/Footer";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Modal from "@src/deprecatedDesignSystem/components/Modal";
import Text from "@ui/text";
import TitleHeader from "@src/deprecatedDesignSystem/components/TitleHeader";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { css, StyleSheet } from "aphrodite";
import { launchConfetti } from "@utils/confetti";
import { gql } from "graphql-request";
import { useAssignLibraryItemModal_LibraryItemQuery } from "./AssignLibraryItemModal.generated";
import {
  AccessType,
  LibraryItemType,
  UserSelectionInput,
} from "@src/types.generated";
import ButtonWithPreview from "@src/deprecatedDesignSystem/components/ButtonWithPreview";
import SelectUsersModal from "./SelectUsersModal";
import UserSelectionPreview from "./UserSelectionPreview";
import { pluralize } from "@src/utils/strings";
import useGetUsersForOneOffAssignment from "./hooks/useGetUsersForOneOffAssignment";
import { useToast } from "@src/hooks/useToast";
import { ASSIGNMENT_REFETCH_QUERIES } from "./constants";
import useUserSelectionInputState from "./hooks/useUserSelectionInputState";
import useUserSelectionPreviewState from "./hooks/useUserSelectionPreviewState";
import CreditsChargeWhenStartedBanner from "./CreditsChargeWhenStartedBanner";
import useAssignLibraryItems from "@hooks/useAssignLibraryItems";
import { useAssignLibraryItemModal_ExistingAssignmentsQuery } from "@components/contentLibrary/assignmentModals/AssignLibraryItemModal.generated";

type Props = {
  creditCostPerAssignment?: number;
  userSelectionInput?: UserSelectionInput;
  libraryItemId: string;
};

const AssignLibraryItemModal: FC<Props> = (props) => {
  const { userSelectionInput, setUserSelectionInput } =
    useUserSelectionInputState({
      input: props.userSelectionInput,
      allowUserTypes: false,
    });
  const selectedUserIds = useGetUsersForOneOffAssignment(userSelectionInput);
  const { showModal, closeModal } = useModal();
  const { addErrorToast } = useToast();
  const { data } = useAssignLibraryItemModal_LibraryItemQuery({
    variables: {
      id: props.libraryItemId,
    },
  });
  const { addToast } = useToast();
  const [assignLibraryItemsMutation] = useAssignLibraryItems({
    onCompleted: (result) => {
      if (result.assignLibraryItems.success) {
        closeModal();
        launchConfetti();
        addToast({
          iconType: "check",
          message: "Assignment successful",
        });
      } else {
        addErrorToast({ ...result, callsite: "one_off_assignment_modal" });
      }
    },
    refetchQueries: ASSIGNMENT_REFETCH_QUERIES,
    variables: {
      input: {
        libraryItemIds: [props.libraryItemId],
        userSelection: userSelectionInput,
      },
    },
  });
  const modalHeader = useMemo(() => {
    const element = (
      <Text type="P2" fontWeight="SemiBold">
        New assignment
      </Text>
    );
    return <TitleHeader element={element} onCancelClick={closeModal} />;
  }, [closeModal]);
  const assign = useCallback(() => {
    assignLibraryItemsMutation();
  }, [assignLibraryItemsMutation]);
  const modalFooter = useMemo(() => {
    const primaryButton = (
      <Button
        text="Assign"
        data-testid="assign-button"
        onClick={assign}
        height="40px"
      />
    );
    return (
      <Footer
        primaryButton={primaryButton}
        cancelTitle="Cancel"
        onCancelClick={closeModal}
      />
    );
  }, [assign, closeModal]);
  const handleOnSelect = useCallback(
    (newInput: UserSelectionInput) => {
      setUserSelectionInput(newInput);
    },
    [setUserSelectionInput],
  );
  const showSelectUsersModal = useCallback(() => {
    showModal(
      <SelectUsersModal input={userSelectionInput} onSelect={handleOnSelect} />,
    );
  }, [handleOnSelect, showModal, userSelectionInput]);
  const contentType =
    LIBRARY_ITEM_TYPE_TO_CONTENT_TYPE[
      data?.LibraryItem?.type || LibraryItemType.Path
    ];
  return (
    <Modal
      width={480}
      header={modalHeader}
      footer={modalFooter}
      dataTestId={"assignment-modal"}
    >
      <AutoLayout
        padding={24}
        minHeight={240}
        direction="vertical"
        spaceBetweenItems={24}
        className={css(styles.modalContent)}
      >
        <AutoLayout spaceBetweenItems={12} alignmentVertical="center">
          <ContentAvatar
            style={{ borderRadius: 8 }}
            contentType={contentType}
            coverImage={data?.LibraryItem?.coverImage}
            media={data?.LibraryItem?.trainingResource?.media}
            defaultBackgroundHashKey={props.libraryItemId}
          />
          <AutoLayout direction="vertical" spaceBetweenItems={2}>
            <Text type="P1" fontWeight="SemiBold">
              {data?.LibraryItem?.name?.en || "Untitled"}
            </Text>
            <Text type="P3" fontWeight="Medium" color={deprecatedTones.gray7}>
              {contentType}
            </Text>
          </AutoLayout>
        </AutoLayout>
        <UserSelectionPreviewButton
          onClick={showSelectUsersModal}
          totalSelectedUsers={selectedUserIds.length}
          input={userSelectionInput}
        />
        <AutoLayout
          spaceBetweenItems={18}
          direction="vertical"
          alignSelf="stretch"
        >
          {props.creditCostPerAssignment &&
            props.creditCostPerAssignment > 0 && (
              <CreditsChargeWhenStartedBanner
                creditCostPerAssignment={props.creditCostPerAssignment}
              />
            )}
          <ConfirmationBanner
            userIds={selectedUserIds}
            libraryItemId={props.libraryItemId}
          />
        </AutoLayout>
      </AutoLayout>
    </Modal>
  );
};

type UserSelectionPreviewButtonProps = {
  input: UserSelectionInput;
  totalSelectedUsers: number;
  onClick: () => void;
};
const UserSelectionPreviewButton: FC<UserSelectionPreviewButtonProps> = (
  props,
) => {
  const { hasSelection } = useUserSelectionPreviewState({ input: props.input });
  const label = useMemo(() => {
    return props.totalSelectedUsers > 0
      ? `Assign to ${props.totalSelectedUsers} ${pluralize(
          "user",
          props.totalSelectedUsers,
        )}`
      : undefined;
  }, [props.totalSelectedUsers]);

  return (
    <ButtonWithPreview
      label={label}
      leftIcon="user-circle"
      placeholder="Select users"
      onClick={props.onClick}
    >
      {hasSelection && <UserSelectionPreview input={props.input} />}
    </ButtonWithPreview>
  );
};

type ConfirmationBannerProps = {
  userIds: number[];
  libraryItemId: string;
  creditCostPerAssignment?: number;
};
const ConfirmationBanner: FC<ConfirmationBannerProps> = (props) => {
  const { data: assignmentsData } =
    useAssignLibraryItemModal_ExistingAssignmentsQuery({
      variables: {
        input: {
          filters: {
            accessTypes: {
              value: [AccessType.Assignment],
            },
            userIds: {
              value: props.userIds,
            },
            libraryItemIds: {
              value: [props.libraryItemId],
            },
          },
        },
      },
    });
  const [numComplete, numInProgress] = useMemo(() => {
    const _numInProgress =
      assignmentsData?.Assignments.objects.filter((a) =>
        ["incomplete", "past_due"].includes(a.status),
      ).length || 0;
    const _numComplete =
      assignmentsData?.Assignments.objects.filter(
        (a) => a.status === "complete",
      ).length || 0;
    return [_numComplete, _numInProgress];
  }, [assignmentsData?.Assignments.objects]);
  const inProgressCopy = useMemo(() => {
    if (props.userIds.length === 1 && numInProgress === 1) {
      return "They are in progress on this assignment and will start over.";
    } else if (props.userIds.length > 1 && numInProgress === 1) {
      return "1 person is in progress on this assignment and will start over.";
    } else {
      return `${numInProgress} people are in progress on this assignment and will start over.`;
    }
  }, [props.userIds.length, numInProgress]);
  const completeCopy = useMemo(() => {
    if (props.userIds.length === 1 && numComplete === 1) {
      return "They have already completed this assignment and will be reassigned.";
    } else if (props.userIds.length > 1 && numComplete === 1) {
      return "1 person has already completed this assignment and will be reassigned.";
    } else {
      return `${numComplete} people have already completed this assignment and will be reassigned.`;
    }
  }, [props.userIds.length, numComplete]);
  const copy = useMemo(() => {
    const firstSentence = `Assign to ${props.userIds.length} ${pluralize(
      "user",
      props.userIds.length,
    )}.`;
    const secondSentence =
      numComplete > 0
        ? completeCopy
        : numInProgress > 0
          ? inProgressCopy
          : numComplete === 0 && numInProgress === 0
            ? `You can always unassign later.`
            : undefined;
    return `${firstSentence} ${secondSentence}`;
  }, [
    completeCopy,
    inProgressCopy,
    numComplete,
    numInProgress,
    props.userIds.length,
  ]);

  if (props.userIds.length === 0) {
    return null;
  }

  return (
    <AutoLayout
      flex={1}
      alignSelf="stretch"
      alignmentVertical="center"
      padding={12}
      spaceBetweenItems={8}
      borderRadius={10}
      className={css(styles.confirmationBanner)}
    >
      <DeprecatedIcon type="check" color={deprecatedTones.blue9} />
      <Text
        type="P2"
        fontWeight="Medium"
        multiline
        color={deprecatedTones.blue12}
      >
        {copy}
      </Text>
    </AutoLayout>
  );
};

const styles = StyleSheet.create({
  modalContent: {
    backgroundColor: deprecatedTones.white,
  },
  confirmationBanner: {
    backgroundColor: deprecatedTones.blue2,
    border: `1px solid ${deprecatedTones.blue3}`,
  },
});

gql`
  query AssignLibraryItemModal_LibraryItem($id: UUID!) {
    LibraryItem(id: $id) {
      id
      coverImage {
        ...CoverImage
      }
      name {
        en
      }
      type
      trainingResource {
        media {
          ...Media
        }
      }
    }
  }
  query AssignLibraryItemModal_ExistingAssignments($input: AssignmentsInput!) {
    Assignments(input: $input) {
      objects {
        id
        user {
          id
        }
        status
      }
    }
  }
`;

export default AssignLibraryItemModal;
