import {
  DecodedValueMap,
  QueryParamConfig,
  SetQuery,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";
import { ArrayParam } from "use-query-params";

export enum ContentTabType {
  ALL_CONTENT = "all",
  COURSES = "courses",
  MODULES = "modules",
  RESOURCES = "resources",
  SKILLS = "skills",
  ARCHIVE = "archive",
}

type ContentParams = {
  tag: QueryParamConfig<string>;
  tagIds: QueryParamConfig<string[]>;
  detailModal: QueryParamConfig<string>;
};

type DecodedQuery = DecodedValueMap<ContentParams>;

type QueryChanges = Partial<DecodedQuery>;

type Return = {
  selectedTab: string | string[];
  setTag: (type: ContentTabType | string) => void;
  tagIds: string[];
  setTagIds: (tagIds: string[]) => void;
  setQuery: SetQuery<ContentParams>;
};

export const useContentQueryParams = (): Return => {
  const router = useRouter();
  const [query, setQueryParam] = useQueryParams({
    tag: withDefault(StringParam, ContentTabType.ALL_CONTENT),
    tagIds: withDefault(ArrayParam, []),
    detailModal: withDefault(StringParam, ""),
  });

  const setQuery = useCallback(
    (
      changes: QueryChanges | ((latestValues: DecodedQuery) => QueryChanges),
    ) => {
      setQueryParam({ ...changes, detailModal: "" });
    },
    [setQueryParam],
  );

  const setTag = useCallback((tag: ContentTabType | string) => {
    setQuery({ tag });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const setTagIds = useCallback(
    (tagIds: string[]) => {
      setQuery({ tagIds });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const tagIds: string[] = useMemo(
    () => query.tagIds.filter((x) => x !== null),
    [query.tagIds],
  ) as string[];
  return useMemo(() => {
    return {
      selectedTab: router.query.tab || "modules",
      tagIds: tagIds,
      setTagIds,
      setTag: setTag,
      setQuery,
    };
  }, [router.query.tab, tagIds, setTagIds, setTag, setQuery]);
};
