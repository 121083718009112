import { LocationGroupType } from "@src/types.generated";
import { DeprecatedIconType } from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import { Route } from "nextjs-routes";

export const locationGroupTypeDisplayName: Record<LocationGroupType, string> = {
  [LocationGroupType.Brand]: "Brand",
  [LocationGroupType.Franchise]: "Franchise",
  [LocationGroupType.Region]: "Region",
};

export const locationGroupPathName: Record<LocationGroupType, Route> = {
  [LocationGroupType.Brand]: { pathname: "/locations/brands" },
  [LocationGroupType.Franchise]: { pathname: "/locations/franchises" },
  [LocationGroupType.Region]: { pathname: "/locations/regions" },
};

export const locationGroupTypeDisplayNamePlural: Record<
  LocationGroupType,
  string
> = {
  [LocationGroupType.Brand]: "Brands",
  [LocationGroupType.Franchise]: "Franchises",
  [LocationGroupType.Region]: "Regions",
};

export const locationGroupTypeIcon: Record<
  LocationGroupType,
  DeprecatedIconType
> = {
  [LocationGroupType.Region]: "globe-with-pin",
  [LocationGroupType.Franchise]: "git-branch",
  [LocationGroupType.Brand]: "registered-trademark",
};
