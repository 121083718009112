import React from "react";
import { LINEAR_GRADIENTS } from "@src/components/ui/CoverImage";
import { BackgroundGradient } from "@src/types.generated";

type Props = {
  logoUrl?: string | null;
  orgId: number;
  size?: number;
};
export const OrgLogo: React.FC<Props> = ({ logoUrl, orgId, size = 24 }) => {
  const gradient = React.useMemo(
    () =>
      LINEAR_GRADIENTS[getGradient(orgId)] ||
      LINEAR_GRADIENTS[BackgroundGradient.Gradient_1],
    [orgId],
  );
  return logoUrl ? (
    <img
      src={logoUrl}
      alt="org-icon"
      className="overflow-hidden rounded-full"
      style={{ width: size, height: size }}
    />
  ) : (
    <div
      style={{
        width: size,
        height: size,
        background: gradient,
      }}
      className="overflow-hidden rounded-full"
    />
  );
};

const getGradient = (id: number): BackgroundGradient => {
  const mod = id % 10;
  switch (mod) {
    case 0:
      return BackgroundGradient.Gradient_1;
    case 1:
      return BackgroundGradient.Gradient_2;
    case 2:
      return BackgroundGradient.Gradient_3;
    case 3:
      return BackgroundGradient.Gradient_4;
    case 4:
      return BackgroundGradient.Gradient_5;
    case 5:
      return BackgroundGradient.Gradient_6;
    case 6:
      return BackgroundGradient.Gradient_7;
    case 7:
      return BackgroundGradient.Gradient_8;
    case 8:
      return BackgroundGradient.Gradient_9;

    default:
      return BackgroundGradient.Gradient_10;
  }
};
